.header-title {
    font-family: "NanumSquareNeoExtraBold";
    font-size: 52px;
    line-height: 78px;
    text-align: center;
}

.header-title span {
    color: #ff4471;
}

.title {
    font-family: "NanumSquareNeoHeavy";
    font-size: 44px;
    line-height: 48.62px;
    color: #111111;
}
.title.pink {
    color: #ff4471;
}

.sub-title {
    font-family: "PretendardRegular";
    font-size: 28px;
    line-height: 42px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #333333;
}

.sub-title span {
    font-family: "PretendardBold";
    color: #ff4471;
}

@media all and (max-width: 1439px) {
    .header-title {
        font-size: 28px;
        line-height: 42px;
        letter-spacing: -0.02em;
    }
    .title {
        font-size: 18px;
        line-height: 19.89px;
    }
    .sub-title {
        font-size: 16px;
        line-height: 22.4px;
        letter-spacing: -0.02em;
        color: #777777;
    }

    .sub-title span {
        font-family: "PretendardSemiBold";
        color: #ff4471;
    }
}
