.last-page {
    height: 1320px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}
.last-page-blur {
    width: 100%;
    height: 336px;
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, rgba(255, 68, 113, 0.1) 0%, rgba(255, 68, 113, 0) 100%);
}

.last-page-imgs {
    margin-top: 40px;
    position: relative;
}

.last-page-img-center {
    width: 437.28px;
    position: relative;
}

.last-page-img-left,
.last-page-img-right {
    height: 726.76px;
    position: absolute;
    top: 48px;
}

.last-page-img-left {
    left: -65%;
}

.last-page-img-right {
    left: 83.5%;
}

.last-page-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.last-page-deco {
    position: absolute;
    top: 65px;
    left: 50%;
    transform: translate(calc(-50% - 15px));
}

@media all and (max-width: 1439px) {
    .last-page {
        height: 656px;
    }
    .last-page-blur {
        height: 238px;
    }
    .last-page-deco {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(calc(-50%));
    }
    .last-page-imgs {
        margin-top: 30px;
    }

    .last-page-img-center {
        width: 176px;
    }

    .last-page-img-left,
    .last-page-img-right {
        height: 225.29px;
        top: 57px;
    }
    .last-page-img-left {
        left: -40%;
    }

    .last-page-img-right {
        left: 77%;
    }
}
