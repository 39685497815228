.first-page {
    background: linear-gradient(130.33deg, #ff4471 13.95%, #ff7c9c 105.89%);
    height: 860px;
    position: relative;
    display: flex;
    gap: 61px;
    justify-content: center;
    align-items: center;
}

.first-page-bg {
    position: absolute;
}

.first-title {
    margin-bottom: 30px;
    font-family: "NanumSquareNeoBold";
    font-size: 32px;
    line-height: 35.36px;
    color: #ffffff;
    opacity: 80%;
}

.first-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
}

.first-left svg {
    display: block;
}

.first-logo {
    margin-bottom: 56px;
}

.first-right {
    position: relative;
}

.first-page-bg-stars {
    position: absolute;
    bottom: 85px;
}
.first-banner-download {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.first-apk-download {
    object-fit: contain;
    width: 400px;
}

.first-banner-apk-download img {
    width: 345px;
    margin-top: -4px;
}

.first-apk {
    margin-top: -4px;
}

.first-right img {
    width: 758px;
    height: 774px;
    margin-top: 40px;
}

.first-page-second {
    position: relative;
    height: 860px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.first-page-second-bg {
    height: 860px;
    object-fit: contain;
}

.first-page-second-phone {
    position: absolute;
    top: 190px;
    width: 1347px;
}

.first-page-second-bubble {
    position: absolute;
    bottom: 290px;
    left: 50%;
    transform: translate(calc(-50% - 400px));
}

.first-page-second-bubble.right {
    bottom: 144px;
    left: 50%;
    transform: translate(calc(-50% + 350px));
}

.first-swiper-btn {
    position: absolute;
    bottom: 60px;
    text-align: center;
    left: 50%;
    transform: translate(calc(-50% + 560px));
    z-index: 3;
}

.first-swiper-btn .right {
    margin-right: 59px;
}
.first-swiper-btn svg {
    cursor: pointer;
}

.first-page-second-title {
    display: flex;
    gap: 224.28px;
    position: absolute;
    top: 74px;
    left: 50%;
    transform: translate(calc(-50% + 60px));
}
.first-page-second-title .logo {
    /* margin-top: 20px; */
}
.first-page .qr-info {
    margin-bottom: 16px;
    gap: 40px;
}
.first-banner-download .qr-info {
    margin-bottom: 16px;
}

.qr-page-body .qr-info {
    gap: 48px;
}

@media all and (max-width: 1439px) {
    .first-page {
        height: 660px;
        gap: 10px;
        flex-direction: column;
        justify-content: start;
    }

    .first-page-bg-light {
        position: absolute;
        bottom: 0px;
    }

    .first-page-bg-light2 {
        position: absolute;
        top: 153px;
    }

    .first-page-bg-icon {
        position: absolute;
        top: -100px;
    }

    .first-page-banner-logo {
        position: absolute;
        top: 60px;
    }
    .first-page-bg-icon2 {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translate(calc(-50% + 30px));
    }

    .first-page-bg-stars2 {
        position: absolute;
        top: 32px;
    }

    .first-page-second {
        height: 660px;
    }

    .first-title {
        margin-bottom: 16.57px;
        font-size: 18px;
        line-height: 19.89px;
        margin-top: 48px;
    }
    .first-logo {
        height: 97px;
        margin-bottom: 35px;
    }

    .first-right img {
        height: 292px;
        margin-top: -10px;
    }

    .first-soon {
        height: 46px;
    }

    .first-left {
        z-index: 2;
    }
    .first-page-bg-stars {
        bottom: 65px;
        left: 50%;
        transform: translate(-50%);
    }

    .first-page-second-bg {
        min-width: 360px;
        max-width: 768px;
        width: 100%;
        height: 660px;
    }
    .first-page-second-phone {
        width: 231px;
        height: 292px;
        top: 329px;
    }
    .first-page-second-bubble {
        height: 44px;
        bottom: 402px;
        left: 50%;
        transform: translate(calc(-50% - 100px));
    }
    .first-page-second-bubble.right {
        bottom: 248px;
        left: 50%;
        transform: translate(calc(-50% + 70px));
    }
    .first-swiper-btn {
        bottom: 22px;
        transform: translate(calc(-50% + 90px));
    }
}
