.qr-page {
    /* height: 794px; */
    background: linear-gradient(115.36deg, #ff4471 23.51%, #ff6288 84.76%);
    position: relative;
}
.qr-page-bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
}

.qr-page-body {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 60px;
    z-index: 2;
    position: relative;
}

.qr-page-title {
    font-family: "NanumSquareNeoHeavy";
    color: #ffffff;
    font-size: 52px;
    line-height: 78px;
    text-align: center;
}
.qr-page-contents {
    font-family: "NanumSquareNeoRegular";
    color: #ffffff;
    font-size: 36px;
    font-weight: 400;
    line-height: 54px;
    text-align: center;
}
.qr-page-apk {
    width: 530px;
    object-fit: contain;
    margin-bottom: -23px;
    margin-top: -14px;
}
.download-button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.download-button {
    width: 520px;
    padding: 26px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: #111111;
    border-radius: 16px;
    height: 90px;
    color: white;
    box-shadow: 0px 4px 20px 0px #80273c4d;
}
.download-button-wrapper.small {
    gap: 16px;
}
.download-button.first {
    width: 345px;
    padding: 20px 0;
    height: 72px;
    font-size: 24px;
}
.download-button.second {
    width: 300px;
    padding: 20px 0;
    height: 64px;
    font-size: 20px;
}
.ic-android {
    width: 33px;
}

@media all and (max-width: 1439px) {
    .qr-page {
        /* height: 454px; */
    }
    .qr-page-body {
        padding-top: 70px;
        gap: 31px;
    }

    .qr-page-title {
        font-size: 28px;
        line-height: 39.2px;
        width: 205px;
    }
    .qr-page-contents {
        font-size: 18px;
        line-height: 27px;
    }
    .qr-page-apk {
        width: 288px;
        object-fit: contain;
        margin-bottom: -23px;
        margin-top: -14px;
    }
    .download-button-wrapper {
        gap: 12px;
    }
    .download-button {
        width: 288px;
        padding: 16px 0;
        height: 56px;
        font-size: 18px;
    }
    .link-btn-mobile {
        display: flex;
        flex-direction:  column;
        gap: 12px;
        margin-top: -130px;
        z-index: 999;
    }
}
