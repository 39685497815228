.payment-success-container {
    font-family: "Pretendard";
    background-color: white !important;
}
.payment-success-container p,
.payment-success-container span {
    color: #000000;
    text-align: center;
}
.payment-success-inner {
    margin: 0 auto;
    width: 100%;
    max-width: 700px;
    padding: 176px 14px 300px;
}
.payment-success-title {
    margin-bottom: 26px;
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 700;
}
.payment-success-content {
    font-size: 18px;
    line-height: 21.6px;
}
.payment-success-content .bold {
    font-weight: 600;
}
.payment-success-price {
    max-width: 506px;
    margin: 60px auto 79px;
    padding: 30px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}
.payment-success-price p {
    font-size: 18px;
    line-height: 25.2px;
}
.payment-success-price p.bold {
    font-weight: 600;
}
.payment-success-contact {
    font-size: 18px;
    line-height: 21.6px;
    color: #00000099 !important;
    margin-bottom: 26px;
}
.payment-success-contact span {
    font-size: 18px;
    line-height: 21.6px;
    color: #00000099 !important;
    margin-bottom: 26px;
}
.payment-success-btn {
    width: 100%;
    background-color: #FF4471;
    color: white;
    padding: 17px 0;
    margin-top: 71px;
    font-size: 16px;
}

@media all and (max-width: 767px) {
    .payment-success-inner {
        padding-bottom: 48px;
    }
    .payment-success-contact span {
        display: block;
    }
    .payment-success-price {
        margin: 44px;
    }
    .payment-success-title {
        margin-bottom: 17px;
    }

    .payment-success-content .block {
        display: block;
        font-weight: 400;
    }
}
