.third-page {
    height: 960px;
    display: flex;
    background: #f6f6f6;
    align-items: center;
    justify-content: center;
    gap: 145px;
    overflow: hidden;
}
.third-left {
    width: 499.27px;
    position: relative;
}

.third-title {
    margin-bottom: 14px;
}

.third-left img {
    width: 499.27px;
    padding-top: 130px;
    z-index: 2;
    position: relative;
}

.third-bg {
    position: absolute;
    left: -25%;
    top: 107px;
}

.third-page .title {
    margin-bottom: 30px;
}

@media all and (max-width: 1439px) {
    .third-page {
        height: 694px;
        padding-top: 70px;
        gap: 0;
        flex-direction: column-reverse;
        position: relative;
        justify-content: start;
    }

    .third-left img {
        height: 443.43px;
        padding-top: 0;
    }

    .third-title {
        height: 27px;
        margin-bottom: 8px;
    }
    .third-right {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .third-left {
        position: absolute;
        left: 50%;
        top: 161px;
        transform: translate(-50%);
    }
    .third-bg {
        left: 50%;
        transform: translate(-50%);
        top: 37px;
    }
    .third-right p {
        text-align: center;
    }
    .third-page .title {
        margin-bottom: 8px;
    }
}
