.second-page {
    height: 960px;
    position: relative;
    overflow: hidden;
}

.second-header-title {
    padding-top: 140px;
}
.second-body {
    display: flex;
    gap: 144px;
    justify-content: center;
    margin-top: 80px;
}
.second-title {
    margin-bottom: 14px;
}

.second-right {
    width: 499.27px;
}

.second-right img {
    width: 499.27px;
    bottom: -170px;
    position: absolute;
    z-index: 2;
}

.second-left {
    margin-top: 125px;
}

.second-left .title {
    margin-bottom: 30px;
}

.second-bg {
    position: absolute;
    left: 36%;
    bottom: -200px;
}

@media all and (max-width: 1439px) {
    .second-page {
        height: auto;
        padding-bottom: 80px;
    }

    .second-header-title {
        padding-top: 80px;
    }
    .second-body {
        flex-direction: column;
        gap: 0px;
        margin-top: 0px;
    }
    .second-title {
        height: 27px;
        margin-bottom: 8px;
    }

    .second-right img {
        height: 489.83px;
        top: 281px;
        left: 50%;
        transform: translate(-50%);
    }
    .second-left {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .second-left p {
        text-align: center;
    }
    .second-left .title {
        margin-bottom: 8px;
    }
    .circle-wrapper {
        margin-top: 360px;
    }
    .second-bg {
        top: 126px;
        height: 649.21px;
        left: 50%;
        transform: translate(-50%);
    }
}
