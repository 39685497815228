.agreement-wrapper {
    padding: 0px 30px;
    max-width: 780px;
    margin: auto;
}

.agreement-wrapper p {
    line-height: 32px;
}

.agreement-wrapper span {
    font-size: 16px;
}

.agreement-title {
    font-family: "NotoSansSemiBold";
    font-size: 25px;
    text-align: center;
}
.agreement-subtitle {
    font-family: "NotoSansSemiBold";
    font-size: 25px;
    text-align: center;
}

.terms-wrapper {
    padding: 120px 0px;
}
