@import "reset.css";
@import "variable.css";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800&display=swap");

@font-face {
    font-family: "AppleSDGothicBold";
    src: url("/src/assets/fonts/AppleSDGothicNeoB.ttf") format("truetype");
}

@font-face {
    font-family: "AppleSDGothicRegular";
    src: url("/src/assets/fonts/AppleSDGothicNeoR.ttf") format("truetype");
}

@font-face {
    font-family: "AppleSDGothicSemiBold";
    src: url("/src/assets/fonts/AppleSDGothicNeoSB.ttf") format("truetype");
}

@font-face {
    font-family: "NanumSquareNeoRegular";
    src: url("/src/assets/fonts/NanumSquareNeo-bRg.ttf") format("truetype");
}

@font-face {
    font-family: "NanumSquareNeoBold";
    src: url("/src/assets/fonts/NanumSquareNeo-cBd.ttf") format("truetype");
}

@font-face {
    font-family: "NanumSquareNeoExtraBold";
    src: url("/src/assets/fonts/NanumSquareNeo-dEb.ttf") format("truetype");
}
@font-face {
    font-family: "NanumSquareNeoHeavy";
    src: url("/src/assets/fonts/NanumSquareNeo-eHv.ttf") format("truetype");
}

@font-face {
    font-family: "PretendardRegular";
    src: url("/src/assets/fonts/Pretendard-Regular.otf") format("truetype");
}

@font-face {
    font-family: "PretendardMedium";
    src: url("/src/assets/fonts/Pretendard-Medium.otf") format("truetype");
}

@font-face {
    font-family: "PretendardSemiBold";
    src: url("/src/assets/fonts/Pretendard-SemiBold.otf") format("truetype");
}

@font-face {
    font-family: "PretendardBold";
    src: url("/src/assets/fonts/Pretendard-Bold.otf") format("truetype");
}

@font-face {
    font-family: "NotoSansRegular";
    src: url("/src/assets/fonts/NotoSansKR-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "NotoSansSemiBold";
    src: url("/src/assets/fonts/NotoSansKR-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Pretendard-Regular";
    src: url("/src/assets/fonts/Pretendard-r.ttf") format("truetype");
}

@font-face {
    font-family: "Pretendard-Medium";
    src: url("/src/assets/fonts/Pretendard-m.ttf") format("truetype");
}

@font-face {
    font-family: "Pretendard-Bold";
    src: url("/src/assets/fonts/Pretendard-Bold.ttf") format("truetype");
}

* {
    box-sizing: border-box;
}
*::-webkit-scrollbar-corner {
    background-color: transparent;
}
*::-webkit-scrollbar {
    display: none;
}

:root {
    font-size: 32px;
}

div[class$="page"] {
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
button,
input,
ul,
li,
div {
    font-style: normal;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 0;
    font-weight: 400;
    white-space: pre-line;
    word-break: keep-all;
    color: #111111;
}

img {
    object-fit: contain;
}

.circle-title {
    font-family: "NanumSquareNeoBold";
    font-size: 23.08px;
    line-height: 25.5px;
    text-align: center;
    margin-top: 29.71px;
    color: #333333;
}

.circle-blur {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 135.94px;
    height: 135.94px;
    box-shadow: 0px 0px 16.48px 0px #ff447180;
    border-radius: 50%;
}

.circle-wrapper {
    display: flex;
    gap: 57px;
    margin-top: 60px;
    z-index: 3;
}

.circle {
    z-index: 3;
}

/* .qr-info-body.big svg { */
/* width: 270px; */
/* height: 270px; */
/* } */

.square {
    width: 292px;
    height: 280px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    box-shadow: 0px 4px 20px 0px #8e354a26;
    position: relative;
}

.sqaure-tag {
    position: absolute;
    left: 30px;
    top: 0px;
    font-family: "NanumSquareNeoExtraBold";
    font-size: 20px;
    line-height: 22.1px;
    text-align: center;
    width: 50px;
    height: 50px;
    padding: 14px 0;
    border-radius: 0px 0px 10px 10px;
    background: #eeeeee;
    color: #777777;
}
.square-icon {
    width: 120px;
    height: 120px;
}
.square-title {
    font-family: "NanumSquareNeoExtraBold";
    font-size: 24px;
    line-height: 26.52px;
    text-align: center;
    color: #333333;
}

.swiper-pagination-fraction {
    position: absolute;
    bottom: 75px;
    text-align: center;
    right: 0;
    left: 50%;
    transform: translate(calc(-50% + 560px));
    font-family: "PretendardMedium";
    font-size: 14px;
    line-height: 16.71px;
    color: #00000080;
    z-index: 3;
}

.swiper-pagination-current {
    color: #111111;
}
.swiper-pagination-total {
    color: #00000080;
}

.qr-info {
    display: flex;
}

@media all and (max-width: 1439px) {
    .circle-title {
        color: #777777;
        font-size: 13px;
        line-height: 14.37px;
        margin-top: 17.14px;
    }

    .circle-blur {
        width: 78.43px;
        height: 78.43px;
        box-shadow: 0px 0px 9.02px 0px #ff447180;
    }
    .circle-blur svg {
        scale: 0.6;
    }

    .circle-wrapper {
        gap: 27px;
    }

    .square {
        width: 150px;
        height: 143px;
        gap: 16.44px;
        border-radius: 10.27px;
    }
    .square-icon {
        width: 61.5px;
        height: 61px;
        /* scale: 0.5; */
    }

    .sqaure-tag {
        width: 25.68px;
        height: 25.68px;
        padding: 7.34px 0px;
        font-size: 10.27px;
        line-height: 11.35px;
        border-radius: 0px 0px 5.14px 5.14px;
        left: 15.41px;
    }

    .square-title {
        font-size: 14px;
        line-height: 15.47px;
        /* margin-top: -22px; */
    }

    .swiper-pagination-fraction {
        bottom: 38px;
        left: 50%;
        transform: translate(calc(-50% + 90px));
    }
    .download-btn {
        display: flex;
        flex-direction: column;
        gap: 13px;
    }
    .download-btn svg {
        cursor: pointer;
    }
}
