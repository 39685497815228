.fourth-page {
    height: 878px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fourth-right {
    width: 292px;
    height: 598px;
    border-radius: 20px;
    position: relative;
    box-shadow: 0px 4px 20px 0px #8e354a33;
    margin-left: 38px;
}

.fourth-right img {
    width: 332px;
    position: absolute;
    bottom: 0;
    left: -20px;
}

.fourth-left-wrapper {
    display: flex;
    gap: 38px;
}

.fourth-left-wrapper.margin {
    margin-bottom: 38px;
}

.fourth-left-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 292px;
    height: 280px;
}

.fourth-left-text {
    font-family: "NanumSquareNeoExtraBold";
    font-size: 52px;
    line-height: 72.8px;
    letter-spacing: -0.01em;
}
.fourth-left-text span {
    color: #ff4471;
}

.fourth-logo {
    width: 292px;
    height: 280px;
    padding: 60px;
}

@media all and (max-width: 1439px) {
    .fourth-page {
        height: auto;
        padding: 80px;
    }
    .fourth-left-wrapper {
        gap: 12px;
    }
    .fourth-left-wrapper.margin {
        margin-bottom: 12px;
    }
    .fourth-left-text {
        font-family: "NanumSquareNeoExtraBold";
        font-size: 24px;
        line-height: 33.6px;
    }
    .fourth-left-text-wrapper {
        width: 150px;
        height: 143px;
    }
    .fourth-logo {
        width: 150px;
        height: 143px;
        padding: 35px;
    }
}
