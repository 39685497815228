.first-container {
    font-family: "Pretendard";
    background-color: white !important;
}
.first-banner-page {
    background: black;
    height: 779px;
    position: relative;
    display: flex;
    gap: 61px;
    justify-content: center;
    align-items: center;
}
.mall-first-subtext {
    line-height: 36px;
    font-size: 18px;
    max-width: 830px;
    padding-left: 27px;
    padding-right: 29px;
}
.item-list {
    background-color: white;
    color: #555555;
    width: 100%;
    margin-top: 140px;
}
.best-list {
    background-color: white;
    color: #555555;
    width: 100%;
    margin-top: 200px;
    padding-bottom: 210px;
}
.best-list .title {
    font-weight: 700;
    font-size: 24px;
    font-weight: 28.8px;
    color: #000000;
}
.item-list .title {
    font-weight: 700;
    font-size: 24px;
    font-weight: 28.8px;
    color: #000000;
}
.first-page-body {
    background: none;
}
.new-item-wrap {
    background-color: white;
    margin: 0 auto;
    max-width: 1176px;
    padding: 0 15px 0 16px;
}
.new-item-wrap p {
    color: #555555;
    text-align: center;
}
.new-item-card {
    width: 100%;
    display: flex;
    margin-top: 32px;
}
.new-item-card-img {
    width: 50%;
    padding-top: 60px;
    padding-left: 62px;
    display: flex;
    flex-direction: column;
    gap: 13.18px;
    background: url("../../assets/images/store/mall_item_ticket_bg.png") 100% 100% / cover no-repeat;
}
.first-page-ticket-sub-img {
    width: 251px;
}
.first-page-ticket-img {
    width: 371px;
}
.new-item-card-content {
    width: 50%;
    border: 1px solid #dddddd;
    padding: 48.47px 30px;
}
.new-item-card-content .title {
    font-size: 24px;
    font-weight: 32px;
    color: #555555;
    margin-bottom: 20px;
}
.new-item-card-content .content {
    color: #00000080;
    font-size: 18.52px;
}
.new-item-card-content .content span {
    font-weight: 600;
    color: #00000080;
}

.new-item-card-content .btn {
    width: 100%;
    padding: 10.8px 0;
    background-color: #FF4471;
    margin-top: 15px;
    font-size: 18.52px;
    line-height: 26px;
    color: white
}
.best-card-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 30px;
    width: calc(100% + 30px);
    margin: 0 auto;
}
.best-card {
    flex: 1 1 calc(33.333% - 30px);
    max-width: calc(33.333% - 30px);
    margin-bottom: 40px;
}
.best-card p {
    text-align: left;
}
.best-card-img {
    width: 100%;
    padding-bottom: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #0000004d;
    background-color: #DDD
}
.best-card-title {
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 700;
    margin-top: 15px;
    color: #000000cc;
}
.best-card-content {
    font-size: 16px;
    line-height: 22.4px;
    color: #000000;
    margin-top: 8px;
}
.best-card-price {
    font-size: 18px;
    line-height: 21.6px;
    color: #00000080;
    font-weight: 800;
    margin-top: 23px;
}
@media all and (max-width: 1176px) {
    .first-page-ticket-sub-img {
        width: 142px;
    }
    .first-page-ticket-img {
        width: 210px;
    }
    .new-item-card-img {
        gap: 7.72px;
    }
}
@media all and (max-width: 767px) {
    .item-list, .best-list {
        margin-top: 0;
    }
    .first-banner-page {
        height: 240px;
        gap: 10px;
        flex-direction: column;
        justify-content: start;
    }
    .first-page-body {
        padding-bottom: 0px;
        padding-top: 0;
    }

    .first-page-bg {
        display: none;
    }

    .first-page-body {
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mall-first-subtext {
        font-size: 10px !important;
        line-height: 20.9px;
    }
    .first-page-title-img {
        width: 360px;
    }
    .new-item-wrap {
        padding-top: 90px;
    }
    .new-item-card {
        flex-direction: column;
    }
    .new-item-card-img {
        padding-top: 27px;
        padding-left: 28px;
        width: 100%;
        height: 208px;
    }
    .new-item-card-content {
        width: 100%;
        padding: 20px 13px;
    }
    .new-item-card-content .title {
        font-size: 16px;
        font-weight: 19px;
        margin-bottom: 13px;
    }
    .new-item-card-content .content {
        font-size: 12px;
        line-height: 20.84px;
    }

    .new-item-card-content .btn {
        font-size: 12px;
        padding: 7px 0;
    }
    .best-card {
        flex: 1 1 calc(50% - 30px);
        max-width: calc(50% - 30px);
    }
    .best-card-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
