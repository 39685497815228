.header {
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 880px;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 10;
}

.header-logo {
    width: 120px;
    height: 45.82px;
}

.header-btn-wrapper {
    display: flex;
    gap: 8px;
}

.header-btn {
    cursor: pointer;
    font-family: "AppleSDGothicSemiBold";
    font-size: 18px;
    line-height: 25.09px;
    padding: 6px 12px 4px;
    color: rgba(119, 119, 119, 1);
}
.header-btn.btn-down {
    background-color: #ff4471;
    border-radius: 5px;
    padding: 6px 16px 4px;
}
.btn-down-text {
    font-size: 16px;
    color: white;
    line-height: 25.09px;
}

.header-btn.active {
    cursor: pointer;
    font-family: "AppleSDGothicBold";
    color: rgba(17, 17, 17, 1);
}

.header-btn.active.shop {
    color: rgba(119, 119, 119, 1);
}
.header-btn.shop-active {
    color: #ff4471;
    font-weight: 600;
}

.footer {
    /* height: 225.82px; */
    background: #eeeeee;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-left: 24px; 
    padding-right: 24px; 
    gap: 32px;
    padding-bottom: 48px;
}
.footer-wrap { 
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.footer-company-title {
    font-family: "PretendardSemiBold";
    font-size: 16px;
    margin-bottom: 16px;
}
.footer-policy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 36px;
    width: 100%;
    margin-bottom: 24px;
}

.footer-policy p {
    font-family: "PretendardSemiBold";
    font-size: 16px;
    line-height: 19.09px;
    color: #777777;
}
.footer-policy .policy {
    cursor: pointer;
}
.footer-policy-border {
    width: 18px;
    height: 1px;
    border: 1px solid #cccccc;
    transform: rotate(-90deg);
}

.footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.footer-email {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 16px;
}
.footer-info p,
.footer-email p {
    font-family: "PretendardMedium";
    font-size: 14px;
    line-height: 14px;
    color: #aaaaaa;
    display: flex;
    gap: 8px;
}
.footer-title {
    color: #777777
}
.footer-term p {
    font-family: "PretendardRegular";
    font-size: 12px;
    line-height: 14px;
    color: #aaaaaa;
    line-height: 16px;
}

@media all and (max-width: 1439px) {
    .header {
        height: 64px;
        justify-content: space-between;
        gap: 0px;
        padding: 16px;
    }

    .header-logo {
        width: 80px;
        height: 30px;
    }
    .header-collapse-btn {
        width: 20px;
        cursor: pointer;
    }
    .header-collapse-body {
        position: absolute;
        top: 64px;
        left: 0;
        width: 100%;
        background: #ffffff;
    }
    .header-collapse-body .download {
        padding: 12px 24px 12px 24px;
        color: #1c1c28;
        font-family: "NotoSansSemiBold";
        font-size: 18px;
        line-height: 23.4px;
        letter-spacing: -0.01em;
    }
    .header-collapse-body .seller_email {
        padding: 12px 0px;
        margin-top: 12px;
        display: block;
        color: #303030;
        font-family: "NotoSansRegular";
        font-size: 14px;
        line-height: 19.07px;
        text-align: left;
        cursor: pointer;
    }
    .header-collapse-body .os {
        padding: 12px 24px;
        color: #303030;
        font-family: "NotoSansRegular";
        font-size: 14px;
        line-height: 19.07px;
        text-align: left;
        cursor: pointer;
    }
    .footer {
        /* height: 192.55px; */
        gap: 24px;
    }
    .footer-policy {
        gap: 12px;
    }

    .footer-policy p {
        font-family: "PretendardMedium";
        font-size: 12px;
        line-height: 14.32px;
    }
    .footer-policy-border {
        width: 14px;
        height: 1px;
    }

    .footer-email p {
        /* text-align: center; */
        font-family: "PretendardMedium";
        font-size: 12px;
        line-height: 14.32px;
    }
    /* .footer-email .email {
        margin-bottom: 8px;
    } */
    .footer-info {
        width: 100%;
        height: 30.55px;
    }
}
