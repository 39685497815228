.payment-container {
    font-family: "Pretendard";
    background-color: white !important;
}
.payment-container p {
    
    color: #000000;
}
.payment-inner {
    margin: 0 auto;
    width: 100%;
    max-width: 748px;
    padding: 0 14px;
}
.payment-top .title {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 700;
    padding-top: 138px;
    margin-bottom: 67px;
}
.goods-wrap {
    padding: 40px 0 42px;
    border-top: 1px solid #0000001a;
    border-bottom: 1px solid #0000001a;
}
.goods-title {
    font-weight: 600;
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 19.2px;
}
.goods-box {
    display: flex;
    gap: 20px;
}
.goods-img-wrap {
    width: 118px;
}
.goods-img {
    width: 100%;
    padding-bottom: 110%;
    background-color: #ddd;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
.goods-content-wrap {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    justify-content: space-between;
}
.goods-top-content {
    display: flex;
    flex-direction: column;
    gap: 1;
}
.goods-top-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
}
.goods-top-sub {
    font-size: 12px;
    line-height: 14.4px;
    font-weight: 400;
}
.user-wrap {
    padding: 24px 0;
}
.user-input-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.user-input-wrap {
    display: flex;
    align-items: "center";
    width: 100%;
}
.input-required {
    padding-left: 5px;
    color: #ff0000;
    font-size: 14px;
    line-height: 16.8px;
}
.user-name-input {
    width: 215px;
}
.user-postcode-input-wrap {
    display: flex;
    gap: 4px;
}
.user-postcode-input-wrap .user-post-btn {
    width: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #00000099;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
}
.user-input-wrap label {
    width: 110px;
    color: #000000;
    font-size: 14px;
    line-height: 39px;
}
.input-address-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: calc(100% - 110px);
}
.user-input-wrap input {
    height: 39px;
    border: 1px solid #0000001a;
    color: #000000;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 400;
}
.user-address-input {
    display: block;
    width: 100%;
}
.user-input-wrap input:disabled {
    background-color: #eee;
}
.user-number-wrap {
    width: 290px;
    display: flex;
    gap: 18.27px;
}
.user-number-input {
    width: 84.33px;
}
.user-email-wrap {
    display: flex;
    align-items: center;
    gap: 5.62px;
}
.user-email-input {
    width: 100%;
    max-width: 253px;
}
.user-email-icon {
    font-size: 22px;
    line-height: 26px;
    color: #00000099 !important;
}
.payment-wrap {
    padding: 40px 0 42px;
    border-top: 1px solid #0000001a;
    border-bottom: 1px solid #0000001a;
}
.payment-wrap .goods-title {
    margin-bottom: 36px;
    font-size: 18px;
    line-height: 21.6px;
}
.payment-content-container {
    display: flex;
    flex-direction: column;
    gap: 26px;
}
.payment-content-wrap {
    display: flex;
    align-items: "center";
    justify-content: space-between;
}
.payment-price {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
}
.text-blue {
    color: #4070ff !important;
}
.payment-title {
    font-size: 14px;
    line-height: 16.8px;
}
.payment-bold {
    font-weight: 700;
}
.payment-method-wrap {
    display: flex;
    align-items: center;
    margin: 37px 0 65px;
    gap: 48px;
}
.payment-card-btn {
    padding: 20px 40.5px;
    border-radius: 5px;
    border: 1px solid #000000b2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
}
.payment-card-btn p {
    font-size: 18px;
    line-height: 21.6px;
    font-weight: 700;
}
.payment-method-wrap .goods-title {
    margin-bottom: 0;
    font-size: 18px;
}
.btn-wrap {
    padding-bottom: 120px;
}
.payment-btn {
    width: 100%;
    padding: 17px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #FF4471;
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
}
.close-btn {
    position: absolute;
    top:50%;
    right: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
    z-index: 9999;
    transform: translate(350px, -300px );
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.close-btn p {
    
    font-size: 18px;
    font-weight: 400;
    color: #333
}
.input-error-text {
    font-size: 12px;
    line-height: 14px;
    color: red;
}
/* .postcode-modal-wrap {
poi
} */
    .postcode-modal-inner {
    width: 100%;
    height: 500px;
    max-width: 700px;
    position: absolute;
    border: 1px solid #DDD;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}
@media all and (max-width: 767px) {
    .goods-img-wrap {
        width: 75px;
    }
    .payment-top .title {
        padding-top: 96px;
        margin-bottom: 20px;
    }
    .user-input-wrap {
        flex-direction: column;
        gap: 8px;
    }
    .input-address-wrap {
        width: 100%;
    }
    .user-input-container{
        gap: 16px;
    }
    .user-number-wrap {
        width: 100%;
        gap: 6px;
    }
    .user-number-input {
        width: 100%;
    }
    .payment-method-wrap{
        flex-direction: column;
        align-items: start;
        gap: 14px;
    }
    .payment-method-wrap {
        margin: 24px 0 49px
    }
    .payment-card-btn {
        width: 100%;
    }
}
