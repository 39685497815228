:root {
    /* --color-primary-black: #000000;
    --color-primary-gray: #eeeeee;
    --color-primary-point: #13ae67;
    --color-black: #1b1b1b;
    --color-black-2: #1a1a1a;
    --color-gray-10: #b3b3b3;
    --color-gray-20: #909090;
    --color-gray-21: #777777;
    --color-gray-30: #404040;
    --color-gray-85: #d9d9d9;
    --color-error: #ca2929;*/
    --screen-mobile: 1440px;
    --border-width: 2px;
    --border-radius: 32px;
    --border-header-radius: 22px;
    --color-1: #fe8357;
}
