.fifth-page {
    height: 1403px;
    background: #f6f6f6;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fifth-bg-left {
    position: absolute;
    left: 0;
    bottom: 0;
}

.fifth-bg-right {
    position: absolute;
    right: 0;
    top: 0;
}

.fifth-page-body {
    width: 1279px;
}

.fifth-title {
    font-family: "NanumSquareNeoExtraBold";
    font-size: 52px;
    line-height: 78px;
    text-align: center;
    z-index: 2;
    margin-bottom: 142px;
}
.fifth-title span {
    color: #ff4471;
}

.fifth-speech {
    width: 573px;
    padding: 46px 40px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.9);
    border: 2px solid #ff4471;
    font-family: "NanumSquareNeoExtraBold";
    font-size: 28px;
    line-height: 30.94px;
    text-align: center;
    color: #333333;
}

.fifth-speech.first {
    margin-top: 106px;
}

.fifth-speech.second {
    width: 683px;
    position: relative;
    margin-left: 189px;
    margin-top: 90px;
    z-index: 2;
}

.fifth-imgs {
    position: relative;
}

.fifth-imgs img {
    position: absolute;
    width: 776px;
    right: 0;
    top: -70px;
}
.fifth-bottom {
    position: relative;
    margin-top: 132px;
}

.fifth-bottom-title {
    font-family: "PretendardSemiBold";
    height: 145px;
    background: #ff4471;
    border-radius: 30px 30px 0px 0px;
    font-size: 28px;
    line-height: 39.2px;
    letter-spacing: -0.01em;
    text-align: center;
    padding-top: 34px;
    color: #ffffff;
}

.fifth-bottom-email {
    font-family: "NanumSquareNeoExtraBold";
    font-size: 38px;
    line-height: 41.99px;
    text-align: center;
    color: #333333;
    height: 162px;
    width: 100%;
    border-radius: 0px 0px 30px 30px;
    background: #ffffff;
    z-index: 2;
    padding-top: 60px;
}

@media all and (max-width: 1439px) {
    .fifth-page {
        height: 787px;
    }
    .fifth-title {
        font-size: 30px;
        line-height: 39px;
        text-align: center;
        margin-bottom: 0;
    }
    .fifth-page-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .fifth-speech {
        width: 270px;
        padding: 14px 0px;
        border-radius: 8px;
        font-size: 14px;
        line-height: 15.47px;
    }

    .fifth-imgs img {
        width: 306px;
        left: 50%;
        transform: translate(-50%);
        top: 208px;
        /* bottom: -300px; */
    }

    .fifth-speech.first {
        margin-top: 40px;
    }

    .fifth-speech.second {
        width: 243px;
        margin-top: 30px;
        margin-left: 69px;
    }

    .fifth-bottom {
        margin-top: 244px;
    }

    .fifth-bottom-title {
        height: 64px;
        border-radius: 12px 12px 0px 0px;
        font-size: 14px;
        line-height: 19.6px;
        padding-top: 12px;
    }

    .fifth-bottom-email {
        font-size: 16px;
        line-height: 17.68px;
        height: 60px;
        width: 312px;
        border-radius: 0px 0px 12px 12px;
        padding-top: 21px;
    }

    .fifth-bg-left {
        bottom: 162px;
    }
}
